import React, { useContext, useState, useRef } from "react"
import Layout from "../../components/layout"
import { StoreContext } from "../../context/store-context"
import { VeraImageHigh } from "../../components/elements/vera-image"
import BlockContent from "@sanity/block-content-to-react"
import SectionColumns from "../../components/elements/section-columns"
import { Link, useStaticQuery, graphql } from "gatsby"
import { asSingle } from "../../util"
import CheckoutHeader from "../../components/header/checkout-header"
import SEO from "../../components/seo"

const Cart = props => {
  const { cart, fullPrice, freeDeliveryCap, code, setCode } = useContext(
    StoreContext
  )
  const [singleItemCart, setSingleItemCart] = useState(asSingle(cart))
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const codeInput = useRef(null)

  const isFreeDelivery = code
    ? fullPrice() - (fullPrice() / 100) * code.code_amount >= freeDeliveryCap
    : fullPrice() >= freeDeliveryCap

  const { sanitySiteInfo } = useStaticQuery(graphql`
    query {
      sanitySiteInfo(site_id: { current: { eq: "veracom" } }) {
        shop_configuration {
          cart_message {
            _key
            _rawContent
            _type
          }
        }
      }
    }
  `)

  const handleCode = async e => {
    e.preventDefault()
    if (
      codeInput.current.value === "" ||
      codeInput.current.value === undefined ||
      codeInput.current.value === null
    ) {
      setError(true)
      return
    }
    setLoading(true)
    const res = await fetch(`${process.env.GATSBY_API_BASE_URL}api/code`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code_value: codeInput.current.value }),
    })

    if (res.status !== 200) {
      setLoading(false)
      setError(true)
      return
    }

    const data = await res.json()
    setLoading(false)
    setError(false)
    setCode(data)
  }

  return (
    <Layout>
      <SEO title="Cart" />
      <CheckoutHeader active="cart" />
      <SectionColumns sectionClasses="cart" isCentered>
        <div className="column is-12-tablet is-11-desktop is-9-widescreen is-9-fullhd">
          <div className="columns">
            <div className="column is-8">
              <div className="cart__basket">
                <h1 className="cart__heading">Basket</h1>
                <div className="cart__info-box">
                  {sanitySiteInfo &&
                    sanitySiteInfo.shop_configuration &&
                    sanitySiteInfo.shop_configuration.cart_message &&
                    sanitySiteInfo.shop_configuration.cart_message.map(c => (
                      <BlockContent key={c._key} blocks={c._rawContent} />
                    ))}
                </div>
                {/* <div className="cart__warning-box">
                  <p>
                    Din levering kan blive forsinkret pga. leveringsudfordringer
                    i visse områder. Læs mere her.
                  </p>
                  <span className="cart__warning-box--close">X</span>
                </div> */}

                <div className="cart__product-list">
                  <Products cart={singleItemCart} />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="cart__overview">
                <h3 className="cart__heading">Overview</h3>
                <div className="cart__info-box" style={{ fontSize: "1rem" }}>
                  {code ? (
                    <div className="cart__price-info">
                      <p>Code:</p>
                      <p style={{ color: "#757575" }}> {code.code_amount} %</p>
                    </div>
                  ) : (
                    <>
                      <p
                        className="cart__info-box-campaign"
                        style={{ marginBottom: "1rem" }}
                      >
                        Do you have a campaign code?
                      </p>
                      {error && (
                        <label className="cart__error">
                          Code does not exist
                        </label>
                      )}
                      <div className="cart__input-wrapper">
                        <form onSubmit={handleCode} style={{ display: "flex" }}>
                          <input
                            ref={codeInput}
                            type="text"
                            className="cart__input"
                            placeholder="Type code"
                          />
                          {cart.length > 0 ? (
                            <button
                              type="submit"
                              className="cart__button"
                              onClick={handleCode}
                            >
                              {loading ? (
                                <div
                                  className="loader is-loading"
                                  style={{ fontSize: 3 }}
                                ></div>
                              ) : (
                                <>Apply code</>
                              )}
                            </button>
                          ) : (
                            <button
                              className="cart__button"
                              disabled
                              title="No items in cart"
                              style={{ cursor: "not-allowed" }}
                            >
                              Apply code
                            </button>
                          )}
                        </form>
                      </div>
                    </>
                  )}
                  <div className="cart__price-info">
                    <p>Subtotal:</p>
                    <p style={{ color: "#757575" }}>{fullPrice()} DKK</p>
                  </div>
                  {code && (
                    <div className="cart__price-info">
                      <p>Discount:</p>
                      <p style={{ color: "#757575" }}>
                        - {Math.round((fullPrice() / 100) * code.code_amount)}{" "}
                        DKK
                      </p>
                    </div>
                  )}
                  <div style={{ marginBottom: 0 }} className="cart__price-info">
                    <p>Shipping:</p>
                    <p style={{ color: "#757575" }}>
                      {isFreeDelivery ? "0" : "50"} DKK
                    </p>
                  </div>
                  <p style={{ color: "#757575", marginBottom: "1rem" }}>
                    Via Pakkeshop Denmark
                  </p>
                  <hr
                    style={{
                      border: "0.5px solid black",
                      marginBottom: "1rem",
                    }}
                  />
                  <div
                    style={{ marginBottom: 0, fontWeight: "bold" }}
                    className="cart__price-info"
                  >
                    <p>Total:</p>
                    <p>
                      {isFreeDelivery
                        ? code
                          ? fullPrice() -
                            Math.round((fullPrice() / 100) * code.code_amount)
                          : fullPrice()
                        : code
                        ? fullPrice() -
                          Math.round((fullPrice() / 100) * code.code_amount) +
                          50
                        : fullPrice() + 50}{" "}
                      DKK
                    </p>
                  </div>
                </div>
                {cart.length > 0 ? (
                  <Link to={"/shop/checkout/"} className="cart__checkout-btn">
                    Checkout
                  </Link>
                ) : (
                  <a
                    className="cart__checkout-btn"
                    disabled
                    style={{ cursor: "not-allowed" }}
                  >
                    Checkout
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </SectionColumns>
    </Layout>
  )
}

const Products = ({ cart }) => {
  return cart.map((product, i) => (
    <div
      key={`product_cart${i}`}
      className="columns cart__product-list is-mobile"
    >
      <div className="column is-3">
        <div className="cart__product-image">
          <Link to={product.link}>
            <VeraImageHigh asset={product.product_image.asset} contain />
          </Link>
        </div>
      </div>
      <div className="column is-7">
        <div className="cart__product-info">
          <div>
            <h4 className="cart__product-title">
              <Link to={product.link}>{product.product_name}</Link>
            </h4>
            <p className="cart__product-variant">
              {product.variant_type} {product.variant.variant_info_value}
            </p>
          </div>
          <div>
            <ShopControls {...product} />
          </div>
        </div>
      </div>
      <div className="column is-2" style={{ marginTop: "0.95rem" }}>
        {product.variant.variant_info_price} DKK
      </div>
    </div>
  ))
}

const ShopControls = ({
  _id,
  variant,
  product_name,
  product_image,
  variant_type,
  link,
}) => {
  const { cart, addToCart, removeFromCart } = useContext(StoreContext)
  const inCart = cart
    .filter(item => item._id === _id)
    .filter(item => item.variant._key === variant._key)

  const addItem = e => {
    e.preventDefault()
    const item = {
      _id,
      product_name,
      product_image,
      variant,
      variant_type,
      link,
    }
    addToCart(item)
  }

  const removeItem = e => {
    e.preventDefault()
    const item = {
      _id,
      product_name,
      variant,
    }
    removeFromCart(item)
  }
  return (
    <div className="shop-controls">
      <button className="shop-btn" onClick={removeItem}>
        <i className="icon-minus" />
      </button>
      <span className="amount-btn">{inCart.length}</span>
      <button className="shop-btn" onClick={addItem}>
        <i className="icon-plus" />
      </button>
    </div>
  )
}

export default Cart
